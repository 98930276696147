<template>
  <base-section
    id="teamfront"
    class="overflow-hidden frame"
  >
    <v-container class="gap">
      <v-row>
        <v-col
          class="accent"
          cols="12"
          md="6"
        >
          <v-img
            :src="require('@/assets/BUZ2.jpg')"
          />
        </v-col>
        <v-col
          class="accent"
          cols="12"
          md="6"
        >
          <v-container>
            <v-row>
              <v-col>
                <div class="ml-5">
                  <h2
                    class="text-md-left mbl text-h5 text-uppercase font-weight-bold primary--text title"
                  >
                    Björn Buz Meier
                  </h2>
                  <div
                    class="py-6"
                  />
                  <h3
                    class="text-h6 secondary--text text-style text-left"
                  >
                    Björn Buz Meier coacht und unterrichtet, mit viel Engagement und Herzblut, Kinder und junge Erwachsene.
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mbl">
                <v-card
                  class="mb-12 mbl"
                />

                <div class="text-md-left">
                  <base-btn
                    :tile="false"
                    color="tertiary"
                    href=""
                    rounded
                    class="mbl"
                  >
                    <router-link
                      class="black--text btn-letters"
                      to="/team"
                      style="text-decoration: none"
                    >
                      Mehr übers Team erfahren
                    </router-link>
                  </base-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script scoped>
  export default {
  }
</script>
<style scoped>
 .frame{
   padding: 0 !important
 }
 .gap{
   max-width: 100%;
 }
 .text-style{
   text-transform: none!important;
 }
 @media screen and (max-width: 600px) {
   .mbl {
     text-align: center!important;
   }
 }
 .btn-letters{
   font-size:0.9em;
 }
</style>
